<template>
  <div>
    <banner />
    <!-- <billing-history /> -->

    <div class="tabs-mf bg-white tab-billing mt-2 mt-md-4">
      <b-tabs data-testid="bottom-box-billing-history">
        <b-tab :active="tab === 'billing_history'">
          <template #title>
            <span
              data-testid="title-billing-history"
              class="tab-title"
            >{{ $t('billingPage.titleBillingHistory') }}</span>
          </template>

          <billing-history />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import {
  BTabs,
  BTab,
} from 'bootstrap-vue'

import envMixin from '@/mixins/envMixin'
import Banner from './components/Banner.vue'
import BillingHistory from './components/BillingHistory.vue'

export default {
  components: {
    BillingHistory,
    Banner,
    BTabs,
    BTab,
  },
  mixins: [envMixin],

  data() {
    return {
      tab: this.$route.query.tab || 'billing_history',
    }
  },

  created() {
    this.tab = this.$route.query.tab || 'billing_history'
  },

}
</script>
<style lang="scss" scoped>
.tab-billing {
  border-radius: var(--border-radius-base);
}
</style>

<style lang="scss">
.tab-billing {
  .nav-tabs {
    margin-bottom: 24px;
    padding: 16px 24px 0;
  }
}
</style>
