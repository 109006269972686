<template>
  <div>
    <div
      v-if="needToBeReviewed"
      class="warning-message"
    >
      <div
        data-testid="alert-wait-review-subs"
        class="d-flex"
      >
        <img
          data-testid="img-warning-review"
          class="alert-img mr-50"
          alt="alert"
          :src="require('@/assets/images/common/ic-alert.svg')"
        >
        <p
          data-testid="text-wait-review-subs"
          class="mb-0"
        >
          {{ $t('billingPage.banner.contentWarningCancellationRequestProcessed') }}
        </p>
      </div>
    </div>
    <div
      v-if="checkHasPlan || isTrialing || needToBeReviewed"
      data-testid="top-box"
      class="d-flex box justify-content-start"
    >
      <div
        class="left-banner"
        :style="{background: checkBackground}"
        :class="{'white-bg': isWhiteAgency}"
      >
        <!-- :style="{backgroundImage: checkBackground}" -->
        <div class="name d-flex justify-content-between flex-wrap">
          <span
            data-testid="item-plan-name"
            class="d-flex align-items-baseline"
          >
            {{ getPlanName }}
          </span>
          <div
            v-if="showBtnUpgrade"
            class="d-flex align-items-center"
          >
            <a
              v-if="guideToUpgradeSubscription && !isBlackAgency"
              class="cursor-pointer text-white d-flex mr-50 align-items-center"
              :href="guideToUpgradeSubscription"
              target="_blank"
            >
              Learn
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="mr-25"
              />
            </a>
            <btn-loading
              v-if="!isTrialing && !isBlackAgency"
              id="trigger-change-plan"
              data-testid="btn-change-plan"
              variant-convert="warning"
              class="font-14"
              pill
              size="sm"
              @click="$router.push('/pricing')"
            >
              {{ $t('billingPage.banner.textChangePlan') }}
            </btn-loading>
            <btn-loading
              v-else-if="isTrialing"
              id="trigger-upgrade-plan"
              data-testid="btn-upgrade-plan"
              variant-convert="warning"
              pill
              size="sm"
              class="font-14 font-medium"
              to="/pricing"
            >
              {{ $t('billingPage.banner.textUpgrade') }}
            </btn-loading>
          </div>
        </div>
        <h1
          data-testid="item-amount-plan"
          class="text-white mb-1"
        >
          {{ amount }}
        </h1>
        <div
          class="plan-status"
          :class="{'warning': needToBeReviewed}"
        >
          <img
            v-if="needToBeReviewed"
            alt="tick"
            :src="require('@/assets/images/common/ic-red-close.svg')"
            data-testid="icon-bef-text"
            class="ic-tick"
          >
          <img
            v-else-if="isWhiteAgency"
            alt="tick"
            :src="require('@/assets/images/common/ic-tick-circle-green.svg')"
            data-testid="icon-bef-text"
            class="ic-tick"
          >
          <img
            v-else-if="checkHasPlan"
            alt="tick"
            :src="require('@/assets/images/common/ic-tick-circle.svg')"
            data-testid="icon-bef-text"
            class="ic-tick"
          >
          <span
            v-if="isTrialing"
            data-testid="text-medium-in-top"
            class="font-medium"
          >
            {{ showTrialDays }} {{ $t('billingPage.banner.textTrialDay') }}
          </span>
          <span
            v-else-if="needToBeReviewed"
            data-testid="text-medium-in-top"
            class="font-medium text-danger"
          >{{ $t('billingPage.banner.textNeedToBeReviewed') }}</span>
          <span
            v-else-if="checkHasPlan"
            data-testid="text-medium-in-top"
            class="font-medium"
          >{{ $t('billingPage.banner.textHasPlan') }}</span>
        </div>
        <div
          v-if="showAddCreditCard"
          class="mt-1"
        >
          <span
            class="font-14"
          >
            {{ $t('billingPage.banner.textAfter') }} {{ showTrialDays }} {{ $t('billingPage.banner.textYouMust') }}
            <span
              data-testid="add-a-credit-card"
              class="font-medium text-uppercase"
              :class="hasStepGuide ? '' : 'underline cursor-pointer'"
              @click="goToAddFundPage"
            >
              {{ $t('billingPage.banner.textAddCreditCard') }}
            </span>
            {{ $t('billingPage.banner.textToContinue') }}
          </span>
        </div>
        <div
          v-else
          class="d-flex flex-wrap justify-content-between"
          style="margin-top: 20px"
        >
          <div
            v-if="currentCreditCardInfo"
            class="font-14 d-flex align-items-center font-medium"
          >
            <img
              alt="brand"
              class="brand-logo"
              :src="isWhiteAgency ? getCreditCardLogo(currentCreditCardInfo.brand) : getNoColorCreditCardLogo(currentCreditCardInfo.brand)"
            >
            <span style="margin: 0 6px 0 3px"> {{ getCreditCardName(currentCreditCardInfo.brand) }}</span>
            <span data-testid="data-last4-card">{{ currentCreditCardInfo.last4 || '' }}</span>
          </div>
          <span
            v-if="!hasStepGuide || amountNumber === 0"
            id="trigger-payment-method-management"
            data-testid="hyperlink-payment-method"
            class="font-14 underline cursor-pointer font-medium"
            @click="$router.push('/payment/add-fund?tab=creditcard')"
          >
            {{ $t('billingPage.banner.titlePaymentMethod') }}
          </span>
        </div>
        <hr class="divider">
        <p
          v-if="timePlanExpired"
          data-testid="item-time-payment-plan"
          class="mb-0 font-14"
        >
          {{ $t('billingPage.banner.textNextPayment') }}: <strong data-testid="item-next-amount">{{ amount }}</strong> {{ $t('billingPage.banner.textOn') }} {{ timePlanExpired }}
        </p>
      </div>
      <div
        data-testid="right-banner-benefit-package"
        class="right-banner w-100"
        :class="{'white-bg': isWhiteAgency}"
      >
        <div class="d-flex justify-content-between align-items-start">
          <h3 class="font-weight-normal mb-1 font-text">
            {{ $t('billingPage.banner.titleBenefit') }}
          </h3>

          <div
            v-if="checkHasPlan && !needToBeReviewed && !isBlackAgency && !isWhiteAgency"
            class="d-flex align-items-center"
          >
            <a
              v-if="guideToCancelSubscription"
              class="cursor-pointer text-green d-flex mr-50 align-items-center"
              :href="guideToCancelSubscription"
              target="_blank"
            >
              Learn
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="mr-25"
              />
            </a>
            <b-button
              variant="flat-primary"
              pill
              size="sm"
              data-testid="right-btn-cancel-plan"
              class="font-14 btn-cancel"
              @click="toggleModal"
            >
              {{ $t('billingPage.banner.textCancelPlan') }}
            </b-button>
          </div>
        </div>
        <p class="font-14">
          {{ $t('billingPage.banner.subTitleBenefit') }}
        </p>
        <hr class="divider">
        <div class="benefits">
          <b-row>
            <b-col
              v-if="maximumAds"
              cols="6"
              class="mb-1 d-flex align-items-baseline"
            >
              <img
                alt="tick"
                :src="require('@/assets/images/common/ic-tick-green.svg')"
                class="icon-tick"
              >
              <span
                data-testid="data-maximum-ads"
                class="font-14"
              >{{ $t('billingPage.banner.textMaximum') }} {{ maximumAds }} {{ $t('billingPage.banner.adAccounts') }}</span>
            </b-col>
            <b-col
              cols="6"
              class="mb-1 d-flex align-items-baseline"
            >
              <img
                alt="tick"
                :src="require('@/assets/images/common/ic-tick-green.svg')"
                class="icon-tick"
              >
              <span
                data-testid="data-limit-spending"
                class="font-14"
              >{{ $t('register.textUnlimitedSpending') }}</span>
            </b-col>
            <b-col
              v-if="checkHasTrialTxt"
              cols="6"
              class="mb-1 d-flex align-items-baseline"
            >
              <img
                alt="tick"
                :src="require('@/assets/images/common/ic-tick-green.svg')"
                class="icon-tick"
              >
              <span
                data-testid="data-show-trial-day"
                class="font-14"
              >{{ showTrialDays }} {{ $t('billingPage.banner.textTrialDay') }} </span>
            </b-col>
            <b-col
              cols="6"
              class="mb-1 d-flex align-items-baseline"
            >
              <img
                alt="tick"
                :src="require('@/assets/images/common/ic-tick-green.svg')"
                class="icon-tick"
              >
              <span
                data-testid="text-support-1-1"
                class="font-14"
              >{{ $t('billingPage.banner.textSupport1vs1') }}</span>
            </b-col>
            <b-col
              v-if="transferFee"
              cols="12"
              class="mb-1 d-flex align-items-baseline"
            >
              <img
                alt="tick"
                :src="require('@/assets/images/common/ic-tick-green.svg')"
                class="icon-tick"
              >
              <span
                class="font-14"
              >{{ $t('billingPage.banner.textServiceFees') }}:</span>
              <b-row class="mx-0">
                <b-col
                  v-if="hasPayFast"
                  cols="6"
                  lg="4"
                  class="pl-1 border-card"
                >
                  <p
                    v-if="payfastFee !== undefined && payfastFee !== null"
                    data-testid="right-data-credit-card-fee"
                    class="font-14 width-max mb-0 mr-1"
                  >
                    PayFast: {{ amountFormat(payfastFee * 100) }}%
                  </p>
                </b-col>
                <b-col
                  v-if="hasCreditCard"
                  cols="6"
                  lg="4"
                  class="pr-0 border-card"
                >
                  <p
                    v-if="creditCardFee !== undefined && creditCardFee !== null"
                    data-testid="right-data-credit-card-fee"
                    class="font-14 width-max mb-0 mr-1"
                  >
                    Credit Card: {{ amountFormat(creditCardFee * 100) }}%
                  </p>
                </b-col>
                <b-col
                  v-if="hasPayoneer"
                  cols="6"
                  lg="4"
                  class="pr-0 border-card"
                >
                  <p
                    v-if="poFee !== undefined && poFee !== null && isEcomdyPlatform"
                    data-testid="right-data-po-fee"
                    class="font-14 width-max mb-0 mr-1"
                  >
                    Payoneer: {{ amountFormat(poFee * 100) }}%
                  </p>
                </b-col>
                <b-col
                  v-if="hasPayoneerTicket"
                  cols="6"
                  lg="4"
                  class="pr-0 border-card"
                >
                  <p
                    v-if="poTicketFee !== undefined && poTicketFee !== null"
                    data-testid="right-data-po-fee"
                    class="font-14 width-max mb-0 mr-1"
                  >
                    {{ hasPayoneer ? 'Payoneer ticket' : 'Payoneer' }}: {{ amountFormat(poTicketFee * 100) }}%
                  </p>
                </b-col>
                <b-col
                  v-if="hasPaypal"
                  cols="6"
                  lg="4"
                  class="pr-0 border-card"
                >
                  <p
                    v-if="payPalFee !== undefined && payPalFee !== null"
                    data-testid="right-data-paypal-fee"
                    class="font-14 width-max mb-0 mr-1"
                  >
                    PayPal: {{ amountFormat(payPalFee * 100) }}%
                  </p>
                </b-col>
                <b-col
                  v-if="hasUSDT"
                  cols="6"
                  lg="4"
                  class="pr-0 border-card"
                >
                  <p
                    v-if="usdtFee !== undefined && usdtFee !== null"
                    data-testid="right-data-usdt-fee"
                    class="font-14 width-max mb-0 mr-1"
                  >
                    USDT: {{ amountFormat(usdtFee * 100) }}%
                  </p>
                </b-col>
                <b-col
                  v-if="hasAirwallexAuto || hasAirwallex"
                  cols="6"
                  lg="4"
                  class="pr-0 border-card"
                >
                  <p
                    v-if="airWallexFee !== undefined && airWallexFee !== null"
                    data-testid="right-data-airwallex-fee"
                    class="font-14 width-max mb-0 mr-1"
                  >
                    Airwallex: {{ amountFormat(airWallexFee * 100) }}%
                  </p>
                </b-col>
                <b-col
                  v-if="hasTransferWise"
                  cols="6"
                  lg="4"
                  class="pr-0 border-card"
                >
                  <p
                    v-if="wiseFee !== undefined && wiseFee !== null"
                    data-testid="right-data-wise-fee"
                    class="font-14 width-max mb-0 mr-1"
                  >
                    Wise: {{ amountFormat(wiseFee * 100) }}%
                  </p>
                </b-col>
                <b-col
                  v-if="hasTazapay"
                  cols="6"
                  lg="4"
                  class="pr-0 border-card"
                >
                  <p
                    v-if="tazapayFee !== undefined && tazapayFee !== null"
                    data-testid="right-data-tazapay-fee"
                    class="font-14 width-max mb-0 mr-1"
                  >
                    Tazapay: {{ amountFormat(tazapayFee * 100) }}%

                  </p>
                </b-col>
                <b-col
                  v-if="hasLocalBank"
                  cols="6"
                  lg="4"
                  class="pr-0 border-card"
                >
                  <p
                    v-if="localBankFee !== undefined && localBankFee !== null"
                    data-testid="right-data-local-bank-fee"
                    class="font-14 width-max mb-0 mr-1"
                  >
                    Local bank: {{ amountFormat(localBankFee * 100) }}%

                  </p>
                </b-col>
                <b-col
                  v-if="hasLianLian ||hasLianLianTicket"
                  cols="6"
                  lg="4"
                  class="pr-0 border-card"
                >
                  <p
                    v-if="lianlianFee !== undefined && lianlianFee !== null"
                    data-testid="right-data-lian-lian-fee"
                    class="font-14 width-max mb-0 mr-1"
                  >
                    LianLian: {{ amountFormat(lianlianFee * 100) }}%
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div
      v-else-if="plansStatus.outOfTrialAndNeedPlan"
      class="alert-subs-box d-flex p-2 align-items-center"
    >
      <img
        alt="image"
        :src="require('@/assets/images/pages/billing/no-billing.png')"
      >
      <div>
        <h3>
          {{ reconfirmPlanStatus ? $t('billingPage.banner.textHaveAlreadyRegistered') : $t('billingPage.banner.textTrialHasEnded') }}
        </h3>
        <div>
          {{ $t('billingPage.banner.textPlease') }}
          <span
            class="underline cursor-pointer text-danger font-medium-1"
            @click="$router.push('/pricing')"
          >
            {{ reconfirmPlanStatus ? $t('common.linkClickHere') : $t('billingPage.banner.textChoose') }}
          </span>
          {{ reconfirmPlanStatus ? $t('billingPage.banner.textToContinuePurchase') : $t('billingPage.banner.textAPlanToContinue') }}
        </div>
      </div>
    </div>
    <div
      v-else
      class="alert-subs-box d-flex p-2 align-items-center"
    >
      <img
        alt="image"
        :src="require('@/assets/images/pages/billing/no-billing.png')"
      >
      <div>
        <h3>
          {{ $t('billingPage.banner.textWeDoNotSeeAny') }}
        </h3>
        <span>
          {{ $t('billingPage.banner.textPleaseUpgradeYourPlan') }}
          <span
            data-testid="hyperlink-click-here"
            class="underline cursor-pointer text-danger font-medium-2"
            @click="$router.push('/pricing')"
          >
            {{ $t('common.linkClickHere') }}
          </span> {{ $t('billingPage.banner.textToUpgradeNow') }}
        </span>
      </div>
    </div>
    <b-modal
      ref="modal-cancel"
      centered
      hide-header
      hide-footer
      size="sm"
    >
      <div data-testid="modal-cancel-container">
        <div
          data-testid="header-modal-cancel-sub"
          class="d-flex justify-content-end top-modal mb-1"
        >
          <div data-testid="icon-modal-container">
            <feather-icon
              data-testid="icon-hide-modal"
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="toggleModal"
            />
          </div>
        </div>
        <div
          data-testid="content-modal-container"
          class="px-1 pb-1"
        >
          <h3
            data-testid="title-modal-cancel"
            class="font-weight-normal mb-2"
          >
            {{ $t('billingPage.banner.titleModalCancel') }}
          </h3>
          <p
            data-testid="content-modal-cancel"
            class="font-14 text-danger"
          >
            {{ $t('billingPage.banner.contentModalCancel') }}
          </p>
          <div
            data-testid="btn-modal-container"
            class="d-flex flex-column mt-2"
          >
            <btn-loading
              :loading="cancelLoading"
              data-testid="btn-cancel-sub"
              pill
              variant-convert="btn-submit"
              style="margin-bottom: 12px"
              @click="cancelSubscription"
            >
              {{ $t('billingPage.banner.buttonCancelSubscription') }}
            </btn-loading>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
/* eslint-disable global-require */

import {
  BRow,
  BCol,
  BButton,
  BModal,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import axios from '@axios'
import _find from 'lodash/find'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import dateTimeFormatMixin from '@/mixins/dateTimeFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import partnerMixin from '@/mixins/partnerMixin'
import billingMix from '@/mixins/billingMixin'
import pricingMixin from '@/mixins/pricingMixin'
import envMixin from '@/mixins/envMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { toastification } from '@core/mixins/toast'
import { SUBSCRIPTION_TYPE } from '@/constants'

const { mapActions, mapGetters } = createNamespacedHelpers('billing')
const { mapGetters: mapGettersAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
const { mapGetters: mapGettersPayment, mapActions: mapActionsPayment } = createNamespacedHelpers('payment')

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BtnLoading,
    // components
  },
  mixins: [numberFormatMixin, dateTimeFormatMixin, toastification, paymentMixin, stepGuideMixin, partnerMixin, billingMix, envMixin, pricingMixin],

  data() {
    return {
      cancelLoading: false,
    }
  },
  computed: {
    ...mapGetters(['listPlan', 'subscriptionStatus']),
    ...mapGettersAuth(['user', 'plansStatus']),
    ...mapGettersPayment(['listCard']),

    title() {
      return this.checkHasPlan ? 'Current subscription plan' : 'You don’t have any plan activate'
    },

    amount() {
      const amount = this.user?.data?.plans?.subscription?.amount || 0
      return this.currencyFormat(amount)
    },

    noCreditCardAlert() {
      return !!((this.isTrialing || this.checkHasPlan) && this.listCard.length === 0) // check if user had 1 subscription already
    },

    currentCreditCardInfo() {
      const obj = _find(this.listCard, { default: true })
      if (obj) {
        return obj
      }

      return null
    },

    checkBackground() {
      // const defaultBg = `url(${process.env.VUE_APP_BILLING_DETAIL_BG || require('@/assets/images/pages/billing/billing-detail-bg.png')})`
      // const blackBg = `url(${require('@/assets/images/pages/billing/black-agency-billing-detail-bg.png')}`
      // const whiteBg = `url(${require('@/assets/images/pages/billing/white-agency-billing-detail-bg.png')}`
      const defaultBg = 'linear-gradient(128.33deg, #81FFF4 -29.89%, #6E44FF 100%)'
      const blackBg = 'linear-gradient(141.33deg, #ccc -86.89%, #000 100%)'
      const whiteBg = 'linear-gradient(128.33deg, #fff -29.89%, #ccc 100%)'
      if (this.isBlackAgency) {
        return blackBg
      }
      if (this.isWhiteAgency) {
        return whiteBg
      }

      return defaultBg
    },

    maximumAds() {
      return this.user?.data?.plans?.adsAccountLimit
    },

    transferFee() {
      return this.user?.data?.plans?.transferFee
    },

    payfastFee() {
      return this.transferFee?.payfast
    },

    creditCardFee() {
      return this.transferFee?.creditCard
    },

    poFee() {
      return this.transferFee?.payoneer
    },

    poTicketFee() {
      return this.transferFee?.payoneerTicket
    },

    payPalFee() {
      return this.transferFee?.paypal
    },

    busdFee() {
      return this.transferFee?.BUSD
    },

    usdtFee() {
      return this.transferFee?.USDT
    },

    airWallexFee() {
      return this.transferFee?.airWallex
    },

    wiseFee() {
      return this.transferFee?.transferWise
    },

    tazapayFee() {
      return this.transferFee?.tazapay
    },

    localBankFee() {
      return this.transferFee?.localBank
    },

    lianlianFee() {
      return this.transferFee?.lianLianTicket
    },

    showTrialDays() {
      const days = this.user?.data?.plans?.subscription?.trial_days
      return days || 0
    },

    checkHasTrialTxt() {
      // Show trial text just for regular_1 and white_agency
      const { activePlan } = this.plansStatus
      return !(this.isBlackAgency
        || activePlan === SUBSCRIPTION_TYPE.regular_2
        || activePlan === SUBSCRIPTION_TYPE.regular_3
        || activePlan === SUBSCRIPTION_TYPE.regular_4
        || activePlan === SUBSCRIPTION_TYPE.regular_5
        || activePlan === SUBSCRIPTION_TYPE.enterprise)
    },

    showBtnUpgrade() {
      return !this.isPartner
    },

    amountNumber() {
      return this.user?.data?.plans?.subscription?.amount
    },

    showAddCreditCard() {
      return this.noCreditCardAlert && !this.isWhiteAgency && !this.isBlackAgency && this.amountNumber > 0
    },
  },

  created() {
    if (this.listPlan.length === 0) { // show plan feature detail
      this.getPricing()
    }
    if (this.listCard.length === 0) {
      this.getListCard()
    }
    this.getSubscriptionStatus()
  },

  methods: {
    ...mapActions(['getPricing', 'getSubscriptionStatus']),
    ...mapActionsAuth(['getAccountInfo']),
    ...mapActionsPayment(['getListCard']),

    goToAddFundPage() {
      if (!this.hasStepGuide) {
        this.$router.push('/payment/add-fund?tab=creditcard')
      }
    },

    async cancelSubscription() {
      this.cancelLoading = true
      try {
        const { id } = this.subscriptionStatus
        await axios.put(`/api/stripe-payment/subscriptions/${id}/cancel`)
        this.cancelLoading = false
        this.toastSuccess('Request cancel subscription success!')
        this.toggleModal()
        this.reFetchUserData()
      } catch (e) {
        this.cancelLoading = false
        this.toastFailure(e.data.message || 'Cancel Subscription Failure!')
      }
    },

    reFetchUserData() {
      this.getAccountInfo() // update plansStatus again
      this.getSubscriptionStatus() // update current subscription again to show alert confirm payment or not
    },

    toggleModal() {
      this.$refs['modal-cancel'].toggle()
    },
  },
}
</script>
<style scoped lang="scss">
  @import '@/assets/scss/variables/_variables.scss';
  .box {
    // max-width: 1100px;

    .left-banner {
      background-repeat: no-repeat;
      background-size: cover;
      color: white;
      width: 650px;
      border-top-left-radius: var(--border-radius-base);
      border-bottom-left-radius: var(--border-radius-base);
      padding: 32px 32px 24px 32px;
      @media (max-width: 767px) {
        padding: 16px;
      }

      .plan-status {
        display: inline-block;
        background-color: rgba(255, 255, 255, 0.1);
        font-weight: bold;
        padding: 5px 10px;
        border-radius: 8px;
        text-transform: uppercase;
        font-size: .75rem;

        &.warning {
          background-color: rgba(255, 255, 255, 0.7);
        }
      }

      .name {
        margin-bottom: 5px;

        span {
          font-size: 20px;
        }
      }

      .divider {
        border-color: rgba(255, 255, 255, 0.3);
        margin: 20px 0;
      }

      .ic-tick {
        width: 16px;
        margin-top: -3px;
        margin-right: 5px;
      }

      .btn-upgrade {
        /*border: none !important;*/
        /*background-color: rgba(250, 179, 69, 0.1)*/
      }

      .btn-cancel {
        border: none !important;
        background-color: white;
      }

      .img-crown {
        width: 17px;
        margin-right: 6px;
      }

      .brand-logo {
        max-width: 40px;
        margin-top: -2px;
      }

      &.white-bg {
        color: #16213e !important;

        h1 {
          color: #16213e !important;
        }

        .plan-status {
          background-color: rgba(22, 33, 62, 0.05) !important;
        }

        .divider {
          border-color: rgba(22, 33, 62, 0.1) !important
        }
      }

      @media (max-width: 767px) {
        border-radius: var(--border-radius-base);
        width: 100%;
        margin-bottom: 1rem;
      }
    }

    .right-banner {
      background-color: #fff;
      border-top-right-radius: var(--border-radius-base);
      border-bottom-right-radius: var(--border-radius-base);
      padding: 32px 32px 24px 32px;
      @media (max-width: 767px) {
        padding: 16px;
      }

      .benefits {
        max-width: 1000px;
      }

      .divider {
        border-color: rgba(22, 33, 62, 0.1)
      }

      &.right-trial-bg {
        background-color: rgba(22, 33, 62, 0.03)
      }

      .btn-cancel {
        background-color: #F5F5F7;
        color: rgba(0, 0, 0, 0.7);
        padding: 9px 16px;
      }

      @media (max-width: 767px) {
        border-radius: var(--border-radius-base);
      }

      &.white-bg {
        background-color: #fff !important;
      }
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  .alert-subs-box {
    background-color: rgba(38, 103, 255, 0.03);
    border-radius: var(--border-radius-base);

    img {
      width: 100px;
      margin-right: 20px;
    }
  }

  .font-text {
    font-size: 20px;
    @media(min-width: 768px) {
      font-size: 24px;
    }
  }

  .icon-tick {
    margin-right: 1rem;
    @media(max-width: 820px) {
      margin-right: 4px;
    }
  }

  .border-card {
    @media(max-width: 820px) {
      padding-left: 8px;
    }
  }

  .warning-message {
    background: rgba(251, 176, 59, 0.1);
    border-radius: var(--border-radius-base);
    padding: 16px;
    margin-bottom: 2rem;
  }
</style>
